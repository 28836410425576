import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import { Container } from "react-bootstrap";
import GeneraldutyImage from "../Assets/generalduty-assistant.jpg";
import { Download } from "react-bootstrap-icons";
import DownloadFile from "../Assets/Student-registaion-form.docx";
import Button from "react-bootstrap/Button";

export default class About extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col>
            <Stack
              gap={3}
              style={{
                backgroundColor: "#00072D",
                height: 50,
                width: "100%",
                color: "white",
                textAlign: "left",
                paddingTop: 5,
              }}
            >
              <h4 style={{ marginTop: 5, marginLeft: "10%", fontWeight: "bold" }}>
                General Duty Assistant
              </h4>
            </Stack>
          </Col>
        </Row>
        <Container>
        <Row style={{padding: 20 }}>
          <Col sm={9}>
            <Row>
              <Col>
                <Stack>
                  <p style={{ color: "#003566" }}>
                    General Duty Assistants are the healthcare professionals who
                    ensure that immediate medical attention and timely
                    assistance are provided to patients in hospitals, clinics,
                    and old age homes. The responsibilities of a GDA are to
                    ensure that those under their care receive timely
                    medication, assist in daily routines and tasks that some
                    patients will not be able to perform themselves, and also
                    help maintain a suitable environment for a patient’s
                    recovery.
                  </p>
                </Stack>
              </Col>
            </Row>
            <Row>
              <Col>
                <Stack>
                <h5 style={{ color: "Red", fontWeight: "bold" }}>
                      Selection Criteria
                    </h5>


                  <ul style={{ color: "#003566" }}>
                    <li>
                      Age Group: The candidates should belong to the age group
                      of 18 to 45 years
                    </li>
                    <li>
                      Educational Qualification: Minimum 12th class
                      qualification
                    </li>
                    <li>
                      Socio-Economic background: Extremely poor families will be
                      given preference
                    </li>
                    <li>
                      Interest: Candidates who understand and show interest in
                      the value proposition of the training programme
                    </li>
                  </ul>
                </Stack>
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Card style={{ width: "14rem", textAlign: "center" }}>
              <Card.Img variant="top" src={GeneraldutyImage} />
              <Card.Body>
                <Card.Text>Student registration form</Card.Text>
                <a href={DownloadFile} download>
                  {" "}
                  <Button variant="primary">
                    Download <Download />
                  </Button>
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </Container>
      </div>
    );
  }
}
