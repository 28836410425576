import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import Ssdevelopment from "../Assets/ss-development.jpg";
import { Download } from "react-bootstrap-icons";
import DownloadFile from "../Assets/Student-registaion-form.docx";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";

export default class About extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col>
            <Stack
              gap={3}
              style={{
                backgroundColor: "#00072D",
                height: 50,
                width: "100%",
                color: "white",
                textAlign: "left",
                paddingTop: 5,
              }}
            >
              <h4 style={{ marginTop: 5, marginLeft: "10%", fontWeight: "bold" }}>
                Soft Skills and Personality Development
              </h4>
            </Stack>
          </Col>
        </Row>
        <Container>
        <Row style={{ padding: 20 }}>
          <Col sm={9}>
            <Row>
              <Col>
                <Stack>
                  <p  style={{ color: "#003566" }}>
                    Soft skills primarily comprise pleasant and appealing
                    personality traits such as self-confidence, positive
                    attitude, emotional intelligence, social grace, flexibility,
                    friendliness and effective communication skills. They play a
                    crucial role in the overall development of individual’s
                    personality and thereby enhancing their career prospects.
                    Hence, selected underprivileged women will also be trained
                    on soft skills and personality development programme which
                    would enhance their employability skills. Gender
                    sensitization, workplace safety, computer skills and
                    functional English will also be covered under this
                    programme.
                  </p>
                </Stack>
              </Col>
            </Row>
            <Row>
              <Col>
                <Stack>
                <h5 style={{ color: "Red", fontWeight: "bold" }}>
                      Selection Criteria
                    </h5>


                  <ul  style={{ color: "#003566" }}>
                    <li>
                      Age Group: The candidates should belong to the age group
                      of 18 to 45 years
                    </li>
                    <li>
                      Educational Qualification: Minimum 12th class
                      qualification
                    </li>
                    <li>
                      Socio-Economic background: Extremely poor families will be
                      given preference
                    </li>
                    <li>
                      Interest: Candidates who understand and show interest in
                      the value proposition of the training programme
                    </li>
                  </ul>
                </Stack>
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Card style={{ width: "14rem", textAlign: "center" }}>
              <Card.Img variant="top" src={Ssdevelopment} />
              <Card.Body>
                <Card.Text>Student registration form</Card.Text>
                <a href={DownloadFile} download>
                  {" "}
                  <Button variant="primary">
                    Download <Download />
                  </Button>
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </Container>
      </div>
    );
  }
}
