import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import QrCodeScan from "../Assets/qrCode.jpg";
import Image from "react-bootstrap/Image";

function QRModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="link"
        onClick={handleShow}
        style={{ textDecoration: "none" }}
      >
        <lable
          style={{
            color: "orange",
            fontWeight: "bold",
            fontStyle: "italic",
            fontSize: 14,
          }}
        >
          CLICK HERE FOR QR CODE TO SCAN{" "}
          <Image src={QrCodeScan} width={20} height={20} /> for your Donation.
        </lable>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>QR Scanner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img className="d-block w-100" src={QrCodeScan} alt="Third slide" />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default QRModal;
