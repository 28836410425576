import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import NavbarComp from "./NavbarComp";

const HomeContainer = () => {
  return (
  
      <Row>
        <Col>
          <Stack gap={3}>
            <NavbarComp />
          </Stack>
        </Col>
      </Row>
    
  );
};

export default HomeContainer;
