import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Banner from "../Assets/apj-banner.jpg";
import { Container } from "react-bootstrap";

function Career() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              style={{ borderRadius: 10 }}
            >
              <Carousel.Item>
                <img className="d-block w-100" src={Banner} alt="Third slide" />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col sm={9}>
            <p style={{ color: "#00072D" }}>
              The above quote by Dr. A.P.J. Abdul Kalam advocates the
              significance of the women empowerment in the growth and
              development of a nation. The project is envisaged with an
              overarching goal of socio-economic empowerment of underprivileged
              women through promotion of sustainable livelihood opportunities.
              The proposed intervention will not only equip the selected
              underprivileged women with vocational skills but would also
              provide sustainable livelihood opportunities. The course
              curriculum encompasses academic programme, on the job training and
              soft skills development.
            </p>
            <p style={{ fontStyle: "italic", fontWeight: "bold", color: "#00072D" }}>
              How Allamsetti Charitable Trust Skill Development selects
              Students?
            </p>
            <h5 style={{ color: "red", fontWeight: "bold" }}>
              Recognition Phase:
            </h5>
            <p style={{ color: "#00072D" }}>
              The recognition phase will lay a strong foundation for the
              execution of the project by capturing socio-economic status of the
              targeted communities, their aspirations and major barriers faced
              by them. A detailed baseline study will be conducted to identify
              potential location, beneficiaries, skill gaps, course requirements
              etc.
            </p>
            <h5 style={{ color: "red", fontWeight: "bold" }}>
              Community Mobilization Phase:
            </h5>
            <p style={{ color: "#00072D" }}>
              The community mobilization is an important step to sensitize,
              engage and build rapport with the targeted community during the
              initial phase of the project. The project team will visit the
              project area, conduct awareness drives and Focus Group Discussions
              (FGDs) with the community. The community will be made aware of the
              training programme and how it can create multitude avenues of
              livelihood opportunities.
            </p>
            <h5 style={{ color: "red", fontWeight: "bold" }}>
              Assessment Phase:
            </h5>
            <p style={{ color: "#00072D" }}>
              Assessment criteria and related parameters will be designed to
              assess the learning outcomes of the candidates. Every trainee
              should score a minimum aggregate passing percentage of 50% to
              successfully clear the training program. The assessment will be
              conducted on the lines of relevant skill council certification to
              ensure transparency and fairness.
            </p>

            <h5 style={{ color: "red", fontWeight: "bold" }}>
              Sustainable Livelihood Phase:
            </h5>
            <p style={{ color: "#00072D" }}>
              The project team will also provide counselling to the trained
              candidates who would enable them to either get absorbed in the
              formal economy, seek self-employment opportunities or they can
              start their own entrepreneurial venture.
            </p>
          </Col>

          <Col
            sm={3}
            
          >
            <Row style={{ backgroundColor: "#00072D",  padding: 20, height:'auto' }}>
            <h5 style={{ color: "orange", fontWeight: "bold" }}>
              Selection Phase
            </h5>
            <p style={{ color: "#fff" }}>
              The project team will identify interested beneficiaries from the
              community who fit into the eligibility criteria, and select 100
              beneficiaries through a rigorous process.
            </p>
            <p
              style={{
                color: "orange",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              How the beneficiaries will be selected and selection criteria:
            </p>
            <ul style={{ color: "#fff" }}>
              <li>
                <label style={{ fontWeight: "bold" }}>Age Group:</label>The
                candidates should belong to the age group of 18 to 45 years
              </li>
              <li>
                <label style={{ fontWeight: "bold" }}>
                  Educational Qualification:
                </label>{" "}
                Minimum 12th class qualification
              </li>
              <li>
                <label style={{ fontWeight: "bold" }}>
                  {" "}
                  Socio-Economic background:
                </label>{" "}
                Extremely poor families will be given preference
              </li>
              <li>
                <label style={{ fontWeight: "bold" }}> Interest:</label>{" "}
                Candidates who understand and show interest in the value
                proposition of the training programme
              </li>
            </ul>
            </Row>
          </Col>

        </Row>
      </Container>
    </div>
  );
}
export default Career;
