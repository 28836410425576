import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import CharityIcon from '../Assets/CharityIcon.jpg';
import CSRIcon from '../Assets/CSRIcon.png';
import A12Icon from '../Assets/12AIcon.png';
import G80gIcon from '../Assets/80gIcon.jpg';

function CardsList() {
  return (
    <Container style={{ marginTop: 20 }}>
      <Row className="box">
        <Col xs={6} md={3} style={{ textAlign: "center" }}>
          <Image
            height={100}
            width={100}
            src={CharityIcon}
            thumbnail
          />
          <Card.Text style={{ fontSize: 10 }}>
            Registration No. 62/03 of 2003 of Book IV Document No: 3904 Dated
            28/11/2001
          </Card.Text>
        </Col>
        <Col xs={6} md={3} style={{ textAlign: "center" }}>
          <Image
            height={100}
            width={100}
            src={CSRIcon}
            thumbnail
          />
          <Card.Text style={{ fontSize: 10 }}>
            CSR Registration No: CSR00017449 Ministry of Corporate Affairs
            Government of India
          </Card.Text>
        </Col>
        <Col xs={6} md={3} style={{ textAlign: "center" }}>
          <Image
            height={100}
            width={100}
            src={A12Icon}
            thumbnail
          />
          <Card.Text style={{ fontSize: 10 }}>
            12A Registration Application Number: 345053021260421 Provisional
            Registration Date: 28-05-2021
          </Card.Text>
        </Col>
        <Col xs={6} md={3} style={{ textAlign: "center" }}>
          <Image
            height={100}
            width={100}
            src={G80gIcon}
            thumbnail
          />
          <Card.Text style={{ fontSize: 10 }}>
            80(G) Income Tax Exemption Grant No. AACTS6848QF20214 Date of
            Approval: 23-09-2021
          </Card.Text>
        </Col>
      </Row>
    </Container>
  );
}

export default CardsList;
