import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Instagram, Envelope, Telephone } from "react-bootstrap-icons";
import LocationImage from "../Assets/Location.jpg";
import QRModal from "./QRModal";

export default class Contact extends Component {
  render() {
    return (
      <div style={{ marginTop: 20 }}>
        <Container className="justify-content-md-center">
          <Row>
            <Col style={{ paddingLeft: 40 }}>
              <p
                className="primary-subheading"
                style={{ fontSize: 26, color: "#00072D", fontWeight: "bold" }}
              >
                <label style={{ color: "red", fontWeight: "bold" }}>
                  Get In Touch!
                </label>
              </p>
              <h6 style={{ color: "red", fontWeight: "bold" }}>Address:</h6>
              <p className="primary-text" style={{ color: "#00072D" }}>
                No #311, 3 rd Floor, Diamond Towers,
              </p>
              <p className="primary-text" style={{ color: "#00072D" }}>
                Beside Belson Taj Hotel, S.D.Road,
              </p>
              <p className="primary-text" style={{ color: "#00072D" }}>
                Secunderabad – 500003
              </p>
              <h6 style={{ color: "red", fontWeight: "bold" }}>Call Us:</h6>
              <Telephone /> &nbsp;
              <label
                className="primary-text"
                style={{ color: "#00072D", marginBottom: 10 }}
              >
                040-31595001
              </label>
              <h6 style={{ color: "red", fontWeight: "bold" }}>Email:</h6>
              <p className="primary-text" style={{ color: "black" }}>
                {" "}
                <Envelope /> &nbsp;
                <a
                  style={{ textDecoration: "none", color: "#00072D" }}
                  href="mailto:apact2003@gmail.com"
                >
                  {" "}
                  apact2003@gmail.com
                </a>
              </p>
              <p className="primary-text" style={{ color: "black" }}>
                {" "}
                <Instagram />{" "}
                <a
                  style={{ textDecoration: "none", color: "#00072D" }}
                  href="https://www.instagram.com/allamsettipaparao?igsh=MW84b2s4dW1oMWcwYQ%3D%3D"
                  target="_blank"
                  rel="instagram"
                >
                  {" "}
                  &nbsp; AllamsettiPaparao Charity
                </a>
              </p>
            </Col>
            <Col>
              <a
                style={{ textDecoration: "none" }}
                href="https://www.google.com/maps/place/Diamond+Towers,+Sarojini+Devi+Rd,+Krishna+Complex,+Kalasiguda,+Secunderabad,+Telangana+500003/@17.4418623,78.4935409,64m/data=!3m1!1e3!4m6!3m5!1s0x3bcb9a173dc17b7d:0x4e21ab46a8ca0108!8m2!3d17.4418636!4d78.4937076!16s%2Fg%2F12j6gq703?entry=ttu&g_ep=EgoyMDI0MDgyMy4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="location"
              >
                <Image src={LocationImage} width={600} />
              </a>
            </Col>
          </Row>
        </Container>
        <Row style={{ marginTop: 20 }}>
          <Col>
            <Row
              style={{
                padding: 20,
                backgroundColor: "#00072D",
                color: "white",
                paddingLeft: 130,
              }}
            >
              <p
                className="primary-subheading"
                style={{
                  fontSize: 26,
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Please Donate
              </p>
              <Col>
                <ul>
                  <li>
                    {" "}
                    <p
                      style={{
                        color: "orange",
                        fontWeight: "bold",
                        fontStyle: "italic",
                        fontSize: 14,
                      }}
                    >
                      &nbsp;&nbsp;&nbsp;PLEASE SEND YOUR DONATION by way of
                      Cheque / DD / Cash in favour of "M/S ALLAMSETTI PAPARAO
                      CHARITABLE TRUST".{" "}
                    </p>
                  </li>
                  <li>
                    <QRModal></QRModal>
                  </li>
                </ul>

                <Row>
                  <Col style={{ textAlign: "center" }}></Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
