import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import OperationTheatreImage from "../Assets/opa.jpg";
import { Download } from "react-bootstrap-icons";
import DownloadFile from "../Assets/Student-registaion-form.docx";
import Button from "react-bootstrap/Button";

export default class About extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col>
            <Stack
              gap={3}
              style={{
                backgroundColor: "#00072D",
                height: 50,
                width: "100%",
                color: "white",
                textAlign: "left",
                paddingTop: 5,
              }}
            >
              <h4 style={{ marginTop: 5, marginLeft: "10%", fontWeight: "bold" }}>
                Operation Theatre technician
              </h4>
            </Stack>
          </Col>
        </Row>
        <Container>
        <Row style={{padding: 20 }}>
            <Col sm={9}>
              <Row>
                <Col>
                  <Stack>
                    <p style={{ color: "#003566" }}>
                      An Operation Theatre (OT) technician, also known as a
                      Surgical Technologist or Operating Room Technician, is a
                      healthcare professional who works in the operating theatre
                      or surgical suite. Their primary role is to assist
                      surgeons, nurses, and other members of the surgical team
                      before, during, and after surgical procedures. The
                      responsibilities of an OT technician can vary depending on
                      the healthcare setting and the specific requirements of
                      the surgical department. However, some common tasks and
                      duties performed by OT technicians include Preparing the
                      Operating Theatre, Assisting during Surgery, Monitoring
                      and Documentation etc.
                    </p>
                  </Stack>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Stack>
                  <h5 style={{ color: "Red", fontWeight: "bold" }}>
                      Selection Criteria
                    </h5>

                    <ul style={{ color: "#003566" }}>
                      <li>
                        Age Group: The candidates should belong to the age group
                        of 18 to 45 years
                      </li>
                      <li>
                        Educational Qualification: Minimum 12th class
                        qualification
                      </li>
                      <li>
                        Socio-Economic background: Extremely poor families will
                        be given preference
                      </li>
                      <li>
                        Interest: Candidates who understand and show interest in
                        the value proposition of the training programme
                      </li>
                    </ul>
                  </Stack>
                </Col>
              </Row>
            </Col>
            <Col sm={3}>
              <Card style={{ width: "14rem", textAlign: "center" }}>
                <Card.Img variant="top" src={OperationTheatreImage} />
                <Card.Body>
                  <Card.Text>Student registration form</Card.Text>
                  <a href={DownloadFile} download>
                    {" "}
                    <Button variant="primary">
                      Download <Download />
                    </Button>
                  </a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
