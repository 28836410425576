import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import DownloadFile from "../Assets/Student-registaion-form.docx";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import LabTechnicianImage from "../Assets/Lab-Technician.jpg";
import { Container } from "react-bootstrap";
import { Download } from "react-bootstrap-icons";

export default class About extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col>
            <Stack
              gap={3}
              style={{
                backgroundColor: "#00072D",
                height: 50,
                width: "100%",
                color: "white",
                textAlign: "left",
                paddingTop: 5,
              }}
            >
              <h3
                style={{ marginTop: 5, marginLeft: "10%", fontWeight: "bold" }}
              >
                {" "}
                Lab Technician
              </h3>
            </Stack>
          </Col>
        </Row>
        <Container>
          <Row style={{ padding: 20 }}>
            <Col sm={9}>
              <Row>
                <Col>
                  <Stack>
                    <p style={{ color: "#003566" }}>
                      Lab technicians play a vital role in scientific, medical,
                      and research fields. They are the backbone of the
                      laboratory, responsible for performing a wide range of
                      essential tasks. Lab technicians ensure accurate and
                      precise results by following established protocols,
                      conducting experiments, collecting and analysing data, and
                      operating sophisticated laboratory equipment.
                    </p>
                  </Stack>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Stack>
                  <h5 style={{ color: "Red", fontWeight: "bold" }}>
                      Selection Criteria
                    </h5>


                    <ul style={{ color: "#003566" }}>
                      <li>
                        Age Group: The candidates should belong to the age group
                        of 18 to 45 years
                      </li>
                      <li>
                        Educational Qualification: Minimum 12th class
                        qualification
                      </li>
                      <li>
                        Socio-Economic background: Extremely poor families will
                        be given preference
                      </li>
                      <li>
                        Interest: Candidates who understand and show interest in
                        the value proposition of the training programme
                      </li>
                    </ul>
                  </Stack>
                </Col>
              </Row>
            </Col>
            <Col sm={3}>
              <Card style={{ width: "14rem", textAlign: "center" }}>
                <Card.Img variant="top" src={LabTechnicianImage} />
                <Card.Body>
                  <Card.Text>Student registration form</Card.Text>
                  <a href={DownloadFile} download>
                    {" "}
                    <Button variant="primary">
                      Download <Download />
                    </Button>
                  </a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
