
import "./App.css";
import HomeContainer from "./Components/HomeContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";

function App() {
  return (
    <div className="App">
      <Row>
        <Col  style={{
              backgroundColor:'orange'
            }}>
          <Stack
            gap={3}
            style={{
              height: 65,
              color: "#051650",
              fontSize: 36,
              textAlign: "center",
              fontFamily:'fantasy',
              letterSpacing: 5
              
            

            }}
          >
            <p style={{ marginTop: 10, marginLeft: 20, fontWeight:'bold', textShadow: '2px 2px 2px white' }}>
            
            ALLAMSETTI PAPARAO CHARITABLE TRUST
            </p>
            
          </Stack>
          <Stack
            gap={3}
            style={{
              height: 40,
              color: "white",
              fontSize: 14,
              textAlign: "center",
              fontFamily:'cursive',
              letterSpacing: 5
            }}
          >
            <p style={{ marginTop: 0, marginLeft: 20, fontWeight:'bold' }}>
            REG NO : 62/03 of 2003 of Book IV
            </p>
            
          </Stack>
         
        </Col>
      </Row>
      <HomeContainer  />

      <Stack
        gap={3}
        style={{  height: 10, marginTop: 10 }}
      ></Stack>
      
    </div>
  );
}

export default App;
